import React from 'react'

import style from './taskBar.module.scss'

interface TaskBarProps {
  status: string
}

const TaskBar: React.FC<TaskBarProps> = ({ status }) => {

  return (
    <>
      <div className={style.statusWrapper}>
        <div className={style.statuses}>
          <span className={style.statusTitle}>Статус</span>
          <span className={style.status}>{ status }</span>
        </div>

        <div className={style.title}>
          Панель задач
        </div>
      </div>
    </>
  )
}

export default TaskBar
import React from 'react'

import style from './loader.module.scss'

interface ILoaderProps {
  width?: number
  height?: number
}

export const Loader: React.FC<ILoaderProps> = ({ width = 40, height = 40 }) => {
  return (
    <div className={style.loaderMat}>
      <div className={style.loader} style={{ width: `${width}px`, height: `${height}px` }}>
        <span className={style.loading}> </span>
      </div>
    </div>
    )
}
import React from 'react'

import { Order } from '../../redux/reducers/ordersReducer'
import { isChangeName, isOptionsToChange, namesProducts, optionsToChange } from '../../utils/functions/changeNameProduct'

import style from './modalOrderInfo.module.scss'

const ModalOrderInfo: React.FC<Order> = (props) => {
  const {
    code, production_date_to,
    delivery_date_to, items
  } = props

  return (
    <div className={style.modal}>
      <h3 className={style.orderTitle}>Договор { code }</h3>

      <div className={style.terms}>
        <div className={style.titleBlockName}>Сроки</div>
        <div className={style.dateItem}>
          <span className={style.dateHead}>Дата изготовления</span>
          <span className={style.dateValue}>{ production_date_to }</span>
        </div>
        <div className={style.dateItem}>
          <span className={style.dateHead}>Дата доставки</span>
          <span className={style.dateValue}>{ delivery_date_to }</span>
        </div>
      </div>

      {
        items.map((elem, index) => (
          <div key={elem.id} className={style.products}>
            <div className={style.info}>
              <h2 className={`${style.titleBlockName} ${style.titleBlockNameBlack}`}>
                Информация о товаре
                “{
                  isChangeName(namesProducts, elem?.product) && isOptionsToChange(optionsToChange, elem?.options)
                    ? elem.product.replace('Д/О', 'Д.к. ')
                    : ` ${elem.product}`
                }”
              </h2>

              <div className={style.tableInfo}>
                <div className={style.infoHead}>
                  <div className={style.number}>№</div>
                  <div className={style.name}>Наименование</div>
                  <div className={style.count}>Кол-во</div>
                </div>

                <div key={elem.id} className={style.infoContent}>
                  <div className={style.number}>{index + 1}</div>
                  <div className={style.name}>{ elem.product }</div>
                  <div className={style.count}>{ elem.quantity }</div>
                </div>
                {
                  elem.options.map((item, idx) => (
                    <div key={item.id} className={style.infoContent}>
                      <div className={style.number}>{ index + 2 + idx }</div>
                      <div className={style.name}>{ item.name }</div>
                      <div className={style.count}>{ item.quantity }</div>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className={style.equipment}>
              <h2 className={style.titleBlockName}>Комплектация
                “{
                  isChangeName(namesProducts, elem?.product) && isOptionsToChange(optionsToChange, elem?.options)
                    ? elem.product.replace('Д/О', 'Д.к. ')
                    : ` ${elem.product}`
                }”</h2>

              <div className={style.tableEquipment}>
                <div className={style.equipmentHead}>
                  <div className={style.equipmentMaterial}>Материал</div>
                  <div className={style.equipmentName}>Наименование</div>
                  <div className={style.equipmentProvider}>Поставщик</div>
                </div>

                {
                  elem.materials.map((item) => (
                    <div key={item.id} className={style.equipmentContent}>
                      <div className={style.equipmentMaterial}>{ item.material }</div>
                      <div className={style.equipmentName}>{ item.name }</div>
                      <div className={style.equipmentProvider}>{ item.provider }</div>
                    </div>
                  ))
                }
              </div>

              <div className={style.legsMoldingTable}>
                <div className={style.legsMoldingHead}>
                  <div className={style.legsMoldingMaterial}>Материал</div>
                  <div className={style.legsMoldingName}>Поставщик</div>
                </div>

                <div className={style.legsMoldingContent}>
                  <div className={style.legsMoldingMaterial}>Ножки</div>
                  <div className={style.legsMoldingName}>{elem.leg}</div>
                </div>

                <div className={style.legsMoldingContent}>
                  <div className={style.legsMoldingMaterial}>Молдинг</div>
                  <div className={style.legsMoldingName}>{elem.molding}</div>
                </div>
              </div>
            </div>

            <div>
              <h3 className={style.titleBlockName}>Примечание</h3>

              <div className={style.noteTable}>
                <div className={style.tk1Head}>Тк1</div>
                <div className={style.tk2Head}>Тк2</div>
                <div className={style.tk1Value}>{ elem.note_tk1 }</div>
                <div className={style.tk2Value}>{ elem.note_tk2 }</div>
                <div className={style.note}>{ elem.note }</div>
              </div>

              <div className={style.scheme}>
                <div className={`${style.titleBlockName} ${style.schemeTitle}`}>Схема товара</div>
                <div className={style.schemeValue}>{ elem.schema }</div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default ModalOrderInfo
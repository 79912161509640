import React from 'react'
import { Tasks } from '../../../../redux/reducers/tasksReducer'
import style from '../../allTasks.module.scss'
import { Loader } from '../../../Loader/Loader'

interface IRow {
  openConfirmModal: () => void,
  showOrder: () => void,
  isDisableButton: boolean,
}

const Row: React.FC<Tasks & IRow> = (props) => {
  const { order: { code, production_date_to, is_asap } = { code: '', production_date_to: '' },
    summ, status, openConfirmModal, showOrder, isDisableButton } = props

  return (
    <>
      <span className={style.empty}>
        {is_asap && (
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={style.arrowTop}>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.634406 6.76559C0.484429 6.61557 0.400177 6.41212 0.400177 6.19999C0.400177 5.98786 0.484429 5.78441 0.634406 5.63439L5.43441 0.834388C5.58443 0.684411 5.78787 0.600159 6.00001 0.600159C6.21214 0.600159 6.41558 0.684411 6.56561 0.834388L11.3656 5.63439C11.5113 5.78527 11.592 5.98735 11.5901 6.19711C11.5883 6.40687 11.5042 6.60752 11.3559 6.75584C11.2075 6.90417 11.0069 6.9883 10.7971 6.99013C10.5874 6.99195 10.3853 6.91131 10.2344 6.76559L6.80001 3.33119V12.6C6.80001 12.8122 6.71572 13.0156 6.56569 13.1657C6.41566 13.3157 6.21218 13.4 6.00001 13.4C5.78783 13.4 5.58435 13.3157 5.43432 13.1657C5.28429 13.0156 5.20001 12.8122 5.20001 12.6V3.33119L1.76561 6.76559C1.61558 6.91556 1.41214 6.99982 1.20001 6.99982C0.987875 6.99982 0.784428 6.91556 0.634406 6.76559Z" fill="#D12424"/>
          </svg>
        )}
      </span>
      <span className={style.code}>{ code }</span>
      <span className={style.date}>{ production_date_to }</span>
      <span className={style.info}>
        <button className={style.buttonShowInfo} onClick={showOrder}>Смотреть</button>
      </span>
      <span className={style.cost}>{ summ }</span>
      <span className={style.status}>
        <button className={style.buttonStatus} onClick={openConfirmModal} disabled={isDisableButton}>
          {
            isDisableButton
              ? <Loader />
              : status
          }
        </button>
      </span>
    </>
  )
}

export default Row
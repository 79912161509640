import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { minLength, required } from '../../../utils/validators/validators'
import { FormData } from '../../../types/typesComponent'

import style from '../login.module.scss'

interface RenderParamsField {
  meta: {
    touched: boolean;
    error: string;
  },
  input: {
    name: string
  }
}
interface RenderField { (params: RenderParamsField): React.ReactNode }

const renderField: RenderField = ({ meta: { touched, error }, input }) => {
  const hasError = touched && error
  const inputError = () => hasError ? `${style.error}` : ''

  return (
    <div className={style.fieldsWrapper}>
      <input className={`${style.idUserInput} ${hasError ? `${inputError()}` : ' '}`}
             {...input}
      />

      {
        hasError && <span className={style.errorPrompt}>{error}</span>
      }
    </div>
  )
}

const minLength6 = minLength(6)

const LoginForm: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit} className={style.form}>
      <Field name='idUser'
             component={renderField}
             type='number'
             validate={[minLength6, required]}
             placeholder='Введите ID'
      />

      <button className={style.buttonEnter}>Войти</button>
    </form>
  )
}

const LoginReduxForm = reduxForm<FormData>({ form: 'login' })(LoginForm)

export default LoginReduxForm
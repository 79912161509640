import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setButtonState, setTaskFromServer } from '../../redux/reducers/tasksReducer'

import style from './getTaskButton.module.scss'
import { AppStateType } from '../../redux/rootReducer'
import { Loader } from '../Loader/Loader'

const GetTaskButton: React.FC = () => {
  const isDisabledButton = useSelector((state: AppStateType) => state.tasks.isDisabledButtonForTask)
  const dispatch = useDispatch()

  const getTasks = () => {
    dispatch(setButtonState(true))
    setTimeout(() => {
      dispatch(setTaskFromServer(() => setButtonState(false)))
    }, 1000)
  }

  return (
    <div>
      <button className={style.button} onClick={getTasks} disabled={isDisabledButton}>
        Получить задачу
        {
          isDisabledButton && <Loader />
        }
      </button>
    </div>
  )
}

export default GetTaskButton
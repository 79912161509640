import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { rootReducer } from './redux/rootReducer'
import thunk from 'redux-thunk'

import App from './App'

import reportWebVitals from './reportWebVitals'

import './index.scss'

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, applyMiddleware(thunk))
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.__store__ = store

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
// eslint-disable-next-line no-undef
document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalWrapper from '../ModalWrapper/ModalWrapper'

import ModalOrderInfo from '../ModalOrderInfo/ModalOrderInfo'

import {
  getAllTasksFromServer,
  setTaskFromServer,
  startTasksListeningForDashboard
} from '../../redux/reducers/tasksReducer'

import { AppStateType } from '../../redux/rootReducer'

import style from './allTasks.module.scss'
import { Loader } from '../Loader/Loader'
import RowWrapper from './RowWrapper/RowWrapper'

const AllTasks: React.FC = () => {
  const activeTasks = useSelector((state: AppStateType) => state.tasks?.activeTasks)
  const order = useSelector((state: AppStateType) => state.orders?.viewOrder)
  const isLoadingTasks = useSelector((state: AppStateType) => state.tasks.isLoadingTasks)

  const dispatch = useDispatch()

  // Сортировка всех задач по дате
  const sortActiveTasks = activeTasks
    .sort((a, b) => (!a.order.is_asap && b.order.is_asap) ? 1 : (b.order.is_asap && a.order.is_asap) ? 0 : -1)
    .sort((a, b) => {
      const dateFirst = a.order.production_date_to.split('.').reverse().join(', ')
      const dateSecond = b.order.production_date_to.split('.').reverse().join(', ')

      const newDateA = new Date(dateFirst)
      const newDateB = new Date(dateSecond)
      return (newDateA < newDateB && a.order.is_asap === b.order.is_asap) ? -1 : (newDateA < newDateB && (a.order.is_asap === b.order.is_asap)) ? 1 : 0
    })

  const getTasks = async () => {
    await dispatch(setTaskFromServer(null))
    await dispatch(getAllTasksFromServer('load'))
  }

  // Запрос на сервер для получения всех задач
  useEffect(() => {
    getTasks()
    dispatch(startTasksListeningForDashboard())
  }, [])

  return (
    <div>
      <div className={ style.table }>
        <div className={ style.head }>
          <span className={ style.empty }> </span>
          <span className={ style.code }>Номер договора</span>
          <span className={ style.date }>Дата изготовления</span>
          <span className={ style.info }>Готовность</span>
          <span className={ style.cost }>Стоимость</span>
          <span className={ style.status }>Готовность</span>
        </div>

        {
          (sortActiveTasks?.length > 0)
            ? <div className={ style.content }>
              {
                sortActiveTasks.map((item, index) => <RowWrapper key={ index }
                                                                 id={ item.id }
                                                                 order={ item?.order }
                                                                 summ={ item?.summ }
                                                                 info={ 'Смотреть' }
                                                                 status={ 'Завершить' }/>)
              }
            </div>
            : <div className={ style.noTasks }>
              {
                isLoadingTasks ? <Loader/> : 'Задач нет'
              }
            </div>
        }
      </div>

      <ModalWrapper>
        <ModalOrderInfo { ...order }/>
      </ModalWrapper>
    </div>
  )
}

export default AllTasks
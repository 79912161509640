import { addressAPISocket } from './api'

let ws: WebSocket | null

const tasksSubscriber = [] as SubscribeType[]

function closeHandler(userId: number) {
  setTimeout(() => createChanel(userId), 3000)
}

const ordersHandler = (action: string) => {
  switch (action) {
    case 'tasks':
      return tasksSubscriber.map(s => s())

    default:
      return null
  }
}

function createChanel(userId: number) {
  ws = new WebSocket(`${addressAPISocket}ws/?${userId}`)

  ws.onmessage = function (e) {
    const data = JSON.parse(e.data)

    if (data.action === 'fetch_instance') {
      ordersHandler(data.data.instance)
    }
  }

  ws.onclose = function (event) {
    if (!event.wasClean) {
      // eslint-disable-next-line no-console
      console.log('Соединение прервано')
      closeHandler(userId)
    }

    tasksSubscriber.length = 0
  }
}

export const wsAPI = {
  start(userId: number): void {
    createChanel(userId)
  },
  stop(): void {
    ws?.close()
  },
  subscribeTasks(callback: SubscribeType): void {
    tasksSubscriber.push(callback)
  }
}

type SubscribeType = () => void

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TaskCard from '../TaskCard/TaskCard'
import GetTaskButton from '../GetTaskButton/GetTaskButton'
import ModalWrapper from '../ModalWrapper/ModalWrapper'
import ModalOrderInfo from '../ModalOrderInfo/ModalOrderInfo'

import { getAllTasksFromServer } from '../../redux/reducers/tasksReducer'

import { AppStateType } from '../../redux/rootReducer'

import style from './newTasks.module.scss'

const NewTask: React.FC = () => {
  const activeTasks = useSelector((state: AppStateType) => state.tasks?.activeTasks)
  const order = useSelector((state: AppStateType) => state.orders?.viewOrder)
  const dispatch = useDispatch()

  // Получение задачи при загрузке страницы
  useEffect(() => {
    if (activeTasks?.length <= 0) {
      dispatch(getAllTasksFromServer('load'))
    }
  }, [])

  return (
    <div className={style.newTasks}>
      {
        (activeTasks?.length > 0)
          ? <>
            {
              activeTasks.map((item, index) => (
                <div key={index}>
                  <TaskCard {...item} />
                </div>
              ))
            }
          </>
          : <GetTaskButton />
      }

      <ModalWrapper>
        <ModalOrderInfo {...order}/>
      </ModalWrapper>
    </div>
  )
}

export default NewTask
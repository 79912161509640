import axios, { AxiosResponse } from 'axios'
import { Task, Tasks } from '../redux/reducers/tasksReducer'
import { IGetPayments } from '../redux/reducers/appReducer'

// Адрес АПИ должен быть строкой, в конце обязателен слэш
// Адрес АПИ для сокетов
export const addressAPISocket = 'wss://goldera-erp.dev.migra.ru/'
// Адрес АПИ для запросов
export const addressAPI = 'https://goldera-erp.dev.migra.ru/api/v1/'

let instance = axios.create({
  baseURL: addressAPI,
})

if (localStorage.getItem('token-employee')) {
  if (localStorage.getItem('testAcc-employee') === 'true') {
    instance = axios.create({
      baseURL: addressAPI,
      headers: {
        'Authorization': `Bearer ${ localStorage.getItem('token-employee') }`
      }
    })
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    instance = axios.create({
      baseURL: addressAPI,
      headers: {
        'Authorization': `Bearer ${ localStorage.getItem('token-employee') }`
      }
    })
  }
}

export const createInstance = (token: string, isTestAccount = false): void => {
  if (isTestAccount) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    instance = axios.create({
      baseURL: addressAPI,
      headers: {
        'Authorization': `Bearer ${ token }`
      }
    })
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    instance = axios.create({
      baseURL: addressAPI,
      headers: {
        'Authorization': `Bearer ${ token }`
      }
    })
  }
}

interface ResponseData {
  data: string,
  status: number,
  statusText: string
}

export const getAuth = {
  login(id: number): Promise<AxiosResponse> {
    return axios.post<ResponseData>(`${ addressAPI }auth/code/`, { code: id })
  },
  refresh(refresh: string): Promise<AxiosResponse> {
    return axios.post(`${ addressAPI }token/refresh/`, { refresh })
  },
  me(): Promise<AxiosResponse> {
    return instance.get('users/me/')
  },
}

export const getTasks = {
  setNewTask(): Promise<Tasks> {
    return instance.get('tasks/get-new/')
      .then(response => response.data)
  },
  cancelTask(data: { id: number }): Promise<Array<Tasks>> {
    return instance.post('tasks/cancel/', data)
      .then(response => response.data)
  },
  getAllTasksInProgress(id: number | null, event: string): Promise<Array<Tasks>> {
    return instance.get(`tasks/?my=true&status=in-progress&debug=${ id },event=${ event }`)
      .then(response => response.data)
  },
  completeTask(id: number, data: { status: string }): Promise<Array<Tasks>> {
    return instance.patch(`tasks/${ id }/`, data)
  },
  reportCompletedTasks(id: number, month: number, year: number): Promise<Array<Task>> {
    return instance.get(`tasks/report/?user=${ id }&month=${ month }&year=${ year }`)
      .then(response => response.data)
  }
}

export const getPayments = {
  getPayments(): Promise<IGetPayments> {
    return instance.get('payments/').then((response) => response.data)
  }
}
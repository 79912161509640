import { SET_ACTIVE_TASKS, SET_BUTTON_STATE, SET_COMPLETED_TASKS, SET_LOADING_TASKS, SET_UPDATED_TASK } from '../../types/typesReducers'
import { getTasks } from '../../api/api'
import { Dispatch } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppStateType } from '../rootReducer'
import { getPaymentsFromServer } from './appReducer'
import { Order } from './ordersReducer'
import { wsAPI } from '../../api/WSapi'
import { downloader } from '../../utils/functions/downloader'

export interface Task {
  order_id: number,
  order_name: string,
  order_items: string[],
  order_end_datetime: string,
  summ: string
}
export interface Tasks {
  id: number,
  order: Order,
  summ: string,
  status: string,
  info: string
}

const initialState = {
  activeTasks: [] as Array<Tasks> | never[],
  completedTasks: [] as Array<Task>,
  isDisabledButtonForTask: false,
  isLoadingTasks: true
}

export type InitialStateType = typeof initialState

export const tasksReducer = (state = initialState, action: Actions): InitialStateType => {
  switch (action.type) {
    case SET_ACTIVE_TASKS:
      return {
        ...state,
        activeTasks: action.tasks,
        isLoadingTasks: false
      }

    case SET_COMPLETED_TASKS:
      return {
        ...state,
        completedTasks: action.tasks,
      }

    case SET_UPDATED_TASK:
      return {
        ...state,
        activeTasks: [...state.activeTasks.filter((item: Tasks) => (item.id === action.task.id) ? false : item)],
        isLoadingTasks: false
      }

    case SET_BUTTON_STATE:
      return {
        ...state,
        isDisabledButtonForTask: action.state
      }

    case SET_LOADING_TASKS:
      return {
        ...state,
        isLoadingTasks: action.flag
      }

    default:
      return state
  }
}

type Actions = SetActiveTasks | SetUpdatedTask | SetButtonStatus | SetLoadingTasks | SetCompletedTasks

interface SetActiveTasks {
  type: typeof SET_ACTIVE_TASKS,
  tasks: Array<Tasks> | never[]
}
interface SetCompletedTasks {
  type: typeof SET_COMPLETED_TASKS,
  tasks: Array<Task> | never[]
}
interface SetUpdatedTask {
  type: typeof SET_UPDATED_TASK,
  task: Tasks
}
interface SetButtonStatus {
  type: typeof SET_BUTTON_STATE,
  state: boolean
}
interface SetLoadingTasks {
  type: typeof SET_LOADING_TASKS,
  flag: boolean
}

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, Actions>

export const setActiveTasks = (tasks: Array<Tasks>): SetActiveTasks => ({ type: SET_ACTIVE_TASKS, tasks })
export const setUpdatedTask = (task: Tasks): SetUpdatedTask => ({ type: SET_UPDATED_TASK, task })
export const setButtonState = (state: boolean): SetButtonStatus => ({ type: SET_BUTTON_STATE, state })
export const setLoadingTasks = (flag: boolean): SetLoadingTasks => ({ type: SET_LOADING_TASKS, flag })
export const setCompletedTasks = (tasks: Array<Task>): SetCompletedTasks => ({ type: SET_COMPLETED_TASKS, tasks })

interface ICallback { (finallyCb: (() => void) | null): void }

export const setTaskFromServer: ICallback = (finallyCb) => {
  return (dispatch: Dispatch) => {
    return getTasks.setNewTask()
      .then((response: Tasks) => {
        if (Object.keys(response).length !== 0) {
          dispatch(setActiveTasks([response]))
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
      .finally(() => {
        if (finallyCb) {
          finallyCb()
        }
        dispatch(setButtonState(false))
      })
  }
}

export const getAllTasksFromServer = (event: string): ThunkType => (dispatch: Dispatch, getState) => {
  dispatch(setLoadingTasks(true))

  return getTasks.getAllTasksInProgress(getState().auth.id, event)
    .then((response: Array<Tasks>) => {
      dispatch(setActiveTasks(response))
    })
    .finally(() => dispatch(setLoadingTasks(false)))
}
export const getReportCompletedTasks = (month: number, year: number): ThunkType => (dispatch: Dispatch, getState) => {
  dispatch(setLoadingTasks(true))

  return getTasks.reportCompletedTasks(getState().auth.id || 0, month, year)
    .then((response: Array<Task>) => {
      dispatch(setCompletedTasks(response))
    })
    .finally(() => dispatch(setLoadingTasks(false)))
}

export const completeTaskToServer = ( id: number, data: { status: string }, cb?: () => void ): ThunkType => {
  return (dispatch: Dispatch, getState) => {
    return getTasks.completeTask(id, data)
      .then((response) => {
        if (getState().auth.code_id.slice(0, 2) === '17') {
          downloader(getState().tasks.activeTasks[0].order.document_link, '_target')
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(setUpdatedTask(response.data))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(getPaymentsFromServer())
      })
      .finally(() => {
        if (cb) {
          cb()
        }
      })
  }
}
export const cancelTaskToServer = ( data: { id: number } ): ThunkType => {
  return (dispatch: Dispatch) => {
    return getTasks.cancelTask(data)
      .then((response) => {
        dispatch(setActiveTasks(response))
      })
  }
}

const tasksHandler = (dispatch: ThunkDispatch<never, never, never>) => () => {
  return dispatch(getAllTasksFromServer('update'))
}

export const startTasksListeningForDashboard = (): ThunkType => async (dispatch) => {
  wsAPI.subscribeTasks(tasksHandler(dispatch))
}

interface Validator {
  (value: string): string | undefined 
}

export const required: Validator = (value: string) => {
  if (value) return undefined

  return 'Обязательное поле'
}

export const minLength = (length: number): (value: string) => void => (value: string) => {
  if (value && value.length < length) return `Минимальная длина ${length} символов`

  return ''
}
import { SET_LOADING, SET_USER_DATA } from '../../types/typesReducers'
import { createInstance, getAuth } from '../../api/api'
import { stopSubmit } from 'redux-form'
import { Dispatch } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'
import { setActiveTasks } from './tasksReducer'

const initialState = {
  id: null as number | null,
  firstName: '',
  lastName: '',
  middleName: '',
  username: '',
  isAuth: false,
  role: '',
  fullName: '',
  isLoadingApp: true,
  code_id: ''
}

export type InitialStateType = typeof initialState

export const authReducer = (state = initialState, action: Actions): InitialStateType => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.data,
        isAuth: action.isAuth,
        fullName: `${action.data.first_name} ${action.data.last_name} ${action.data.middle_name}`,
        isLoadingApp: false
      }

    case SET_LOADING:
      return {
        ...state,
        isLoadingApp: action.flag
      }

    default:
      return state
  }
}

type Actions = setUserData | setLoading

interface DataUser {
  first_name: string,
  id: number | null,
  last_name: string,
  middle_name: string,
  username: string,
  role: string
  code_id: string
}
interface setUserData {
  type: typeof SET_USER_DATA,
  data: DataUser,
  isAuth: boolean
}
interface setLoading {
  type: typeof SET_LOADING,
  flag: boolean
}

export const setAuthUserData: (data: DataUser, isAuth: boolean) => setUserData = (
                                 data: DataUser, isAuth: boolean) => {
  return ({ type: SET_USER_DATA, data, isAuth })
}
export const setLoading = (flag: boolean): setLoading => ({ type: SET_LOADING, flag })

export const login = (id: number): (dispatch: Dispatch) => Promise<void> => (dispatch) => {
  return getAuth.login(id)
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        localStorage.setItem('token-employee', response.data.access)
        localStorage.setItem('refresh-employee', response.data.refresh)
        localStorage.setItem('testAcc-employee', 'true')

        // if (id.toString() === '100111') {
        //   createInstance(response.data.access, true)
        // } else {
        //   createInstance(response.data.access, false)
        // }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore @typescript-eslint/ban-ts-comment
        dispatch(getAuthUserData())
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error)
        dispatch(stopSubmit('login', { _error: 'Неправильный логин или пароль' }))
    })
    .finally(() => {
      dispatch(stopSubmit('login', { _error: 'Неправильный логин или пароль' }))
    })
}

export const refreshToken = (refresh: string): (dispatch: Dispatch) => Promise<void> => (dispatch: Dispatch) => {
  return getAuth.refresh(refresh)
    .then((response: AxiosResponse) => {
      localStorage.setItem('token-employee', response.data.access)
      localStorage.setItem('refresh-employee', response.data.refresh)

      // sessionStorage.setItem("token-employee", response.data.access)
      // sessionStorage.setItem("refresh", response.data.refresh)

      // dispatch(setToken(response.data.access, response.data.refresh))
      dispatch(setAuthUserData({} as DataUser, false))

      createInstance(response.data.access, !!localStorage.getItem('testAcc-employee') || false)
    })
    // .catch((error: any) => {
    //   if (error.response) dispatch(showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
    //   else dispatch(showPrompt('ALARM', `Ошибка сети`))
    // })
}

export const getAuthUserData = (): (dispatch: Dispatch) => Promise<void> | void => {
  return (dispatch) => {
    const token = localStorage.getItem('token-employee')
    const isTestAcc = localStorage.getItem('testAcc-employee')

    if (token) {
      if (isTestAcc) {
        createInstance(token, !!isTestAcc)
      } else {
        createInstance(token, false)
      }
    } else {
      dispatch(setAuthUserData({} as DataUser, false))
      return
    }

    return getAuth.me()
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch(setAuthUserData(response.data, true))
          // dispatch(setToken(response.data.token, response.data.refresh))
        }
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          // eslint-disable-next-line no-console
          console.log('me')
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(refreshToken(localStorage.getItem('refresh-employee')))
        }

        // if (error.response) dispatch(showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
        // else dispatch(showPrompt('ALARM', 'Ошибка сети'))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const logout = (): (dispatch: Dispatch) => void => (dispatch: Dispatch) => {
  localStorage.removeItem('token-employee')
  localStorage.removeItem('refresh-employee')
  localStorage.removeItem('testAcc-employee')

  dispatch(setAuthUserData({} as DataUser, false))
  dispatch(setActiveTasks([]))
}
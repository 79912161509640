import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './statistic.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getReportCompletedTasks } from '../../redux/reducers/tasksReducer'
import { AppStateType } from '../../redux/rootReducer'
import useOnClickOutside from '../../utils/hooks/useOnClickOutside'
import { getYear } from '../../utils/functions/getYear'
import { normalizeDate } from '../../utils/functions/normalizeDate'

const monthsNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

const Statistic: FC<{ closeModal: (bool: boolean) => void }> = ({ closeModal }) => {
  const report = useSelector((state: AppStateType) => state.tasks.completedTasks)
  const [tab, setTab] = useState<number>(1)
  const modalRef = useRef<HTMLDivElement>(null)
  const date = new Date()
  const monthPrev2 = date.getMonth() - 2
  const monthPrev = date.getMonth() - 1
  const monthCurr = date.getMonth()
  const yearCurr = date.getFullYear()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getReportCompletedTasks(monthCurr, yearCurr))
  }, [])

  useEffect(() => {
    const needMonth = (() => {
      switch (tab) {
        case 3:
          return monthPrev2
        case 2:
          return monthPrev
        case 1:
          return monthCurr
        default:
          return monthCurr
      }
    })()
    dispatch(getReportCompletedTasks(needMonth >= 0 ? needMonth : (needMonth + 12), getYear(needMonth, yearCurr)))
  }, [tab])

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(report)
  }, [report])

  useOnClickOutside(modalRef, () => closeModal(false))

  return (
    <div className={ styles.modal } ref={ modalRef }>
      <div className={ styles.contentWrap }>
        <button className={ styles.btnClose } onClick={ () => closeModal(false) }>X</button>
        <h3 className={ styles.title }>Статистика начисления заработной платы</h3>
        <div className={ styles.management }>
          <button onClick={ () => setTab(3) }
                  className={ tab === 3 ? styles.active : '' }>{ monthsNames[monthPrev2 >= 0 ? monthPrev2 : (monthPrev2 + 12)] }</button>
          <button onClick={ () => setTab(2) }
                  className={ tab === 2 ? styles.active : '' }>{ monthsNames[monthPrev >= 0 ? monthPrev : (monthPrev + 12)] }</button>
          <button onClick={ () => setTab(1) }
                  className={ tab === 1 ? styles.active : '' }>{ monthsNames[monthCurr] }</button>
        </div>

        <div>
          <div className={ styles.head }>
            <div className={ styles.headCell }>№ договора</div>
            <div className={ styles.headCell }>Товары</div>
            <div className={ styles.headCell }>Дата завершения</div>
            <div className={ styles.headCell }>Сумма</div>
          </div>

          {
            report.length
              ? report.map((item, idx) => (
                <div className={ styles.content } key={ idx }>
                  <div className={ styles.order }>{ item.order_name }</div>
                  <div className={ styles.goods }>
                    { item.order_items.map((el, index) => (
                      <div key={ index } className={ styles.cell }>{ el }</div>
                    )) }
                  </div>
                  <div
                    className={ `${ styles.cell } ${ styles.endDatetime }` }>{ normalizeDate(item.order_end_datetime) }</div>
                  <div className={ `${ styles.cell } ${ styles.sumTask }` }>{ item.summ }</div>
                </div>
              ))
              : <div className={ `${styles.cell} ${styles.emptyData}` }>Данные за период отсутствуют</div>
          }
          <div className={ styles.footer }>
            <div className={ `${ styles.cell } ${ styles.sumTitle }` }>Итого</div>
            <div className={ `${ styles.cell } ${ styles.sum }` }>
              {
                report.reduce((acc: number, item: { summ: string | number }) => acc + (+item.summ), 0).toFixed(0)
              } руб
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Statistic
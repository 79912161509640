export const SET_MODAL = 'app/SET_MODAL'
export const SET_PAYMENTS = 'app/SET_PAYMENTS'
export const SET_USER_DATA = 'auth/SET_USER_DATA'
export const SET_ACTIVE_TASKS = 'tasks/SET_ACTIVE_TASKS'
export const SET_COMPLETED_TASKS = 'tasks/SET_COMPLETED_TASKS'
export const SET_UPDATED_TASK = 'tasks/SET_UPDATED_TASK'
export const SET_LOADING = 'tasks/SET_LOADING'
export const SET_LOADING_TASKS = 'tasks/SET_LOADING_TASKS'
export const SET_ORDER = 'orders/SET_ORDER'
export const SET_BUTTON_STATE = 'orders/SET_BUTTON_STATE'

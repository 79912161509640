import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppStateType } from '../../redux/rootReducer'
import { setOpenModal } from '../../redux/reducers/appReducer'

import useOnClickOutside from '../../utils/hooks/useOnClickOutside'

import style from './modal.module.scss'

const ModalWrapper: React.FC = ({ children }) => {
  const isOpenModal = useSelector((state: AppStateType) => state.app?.isOpenModal)
  const modalRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  const handleClickOutside = () => {
    dispatch(setOpenModal(false))
  }
  useOnClickOutside(modalRef, handleClickOutside)
  
  if (!isOpenModal) return null

  return (
    <div className={style.modalWrapper} ref={modalRef}>
      { children }
    </div>
  )
}

export default ModalWrapper
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { logout } from '../../redux/reducers/authReducer'
import { getPaymentsFromServer } from '../../redux/reducers/appReducer'

import { AppStateType } from '../../redux/rootReducer'

import style from './header.module.scss'

import logo from './../../assets/images/logo.svg'
import Statistic from '../Statistic/Statistic'

const Header: React.FC = () => {
  const payments = useSelector((state: AppStateType) => state.app.payments)
  const fullName = useSelector((state: AppStateType) => state.auth.fullName)
  const [viewStatistic, setViewStatistic] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPaymentsFromServer())
  }, [])

  const exit = () => {
    dispatch(logout())
  }

  return (
    <div className={ style.header }>
      <div className={ style.logo }>
        <img src={ logo } alt="Agenda"/>
      </div>

      <div className={ style.counterWrapper }>
        <div className={ style.counter }>
          { payments } <span className={ style.currency }>руб.</span>
        </div>
        <div className={ style.counterText }>Заработная плата</div>
      </div>

      <div className={ style.statistic }>
        <button className={ style.btnStatistic } onClick={ () => setViewStatistic(true) }>
          <span className={ style.statisticIcon }>
            <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 7.5V13.5M4 13.5V0.5M7.5 13.5V5M10.5 13.5V10" stroke="#3A3A3A" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
          </span>
          <span>См. статистику</span>
        </button>
      </div>

      <div className={ style.accountInfo }>
        <div className={ style.userName }>{ fullName }</div>
        <div className={ style.exit } onClick={ exit }>Выйти</div>
      </div>

      { viewStatistic && <Statistic closeModal={ setViewStatistic } /> }
    </div>
  )
}

export default Header
export const namesProducts = ['Д/О', 'ОПТ Д/О']
export const optionsToChange = ['Спальное место', 'ОПТ Спальное место', 'Сп. Место']

interface IOptions {
  cost?: number
  discount?: number
  id?: number
  name: string
  order_item?: number
  price?: number
  quantity?: string
  sum?: number
}

// проверяем есть ли в массиве имен подстрока true/false
export const isChangeName = (namesArr: Array<string>, name: string): boolean => {
  return namesArr.some(item => name?.toLowerCase().search(item?.toLowerCase()) >= 0)
}

// проверяем есть ли у массива опций опции, из-за которых нужно изменить название товара
export const isOptionsToChange = (namesOptions: Array<string>, options: Array<IOptions>): boolean => {
  return options.some((el) => {
    return namesOptions.some((item: string) => {
      const pos = el?.name?.toLowerCase().search(item?.toLowerCase())
      return pos >= 0
    })
  })
}
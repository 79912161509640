import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { appReducer } from './reducers/appReducer'
import { authReducer } from './reducers/authReducer'
import { tasksReducer } from './reducers/tasksReducer'
import { ordersReducer } from './reducers/ordersReducer'

export const rootReducer = combineReducers({
  form: formReducer,
  app: appReducer,
  auth: authReducer,
  tasks: tasksReducer,
  orders: ordersReducer
})

type RootReducerType = typeof rootReducer
export type AppStateType = ReturnType<RootReducerType>
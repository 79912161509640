import { SET_ORDER } from '../../types/typesReducers'

export interface IOptions {
  id: number,
  quantity: string,
  name: string,
}
export interface IMaterials {
  id: number,
  material: string,
  name: string,
  provider: string,
}
interface Items {
  id: number,
  options: Array<IOptions>,
  materials: Array<IMaterials>,
  leg: string | null,
  molding: string | null,
  quantity: string,
  product: string,
  schema: string,
  note_tk1: string,
  note_tk2: string,
  note: string,
  order: number
}

export interface Order {
  id: number,
  items: Array<Items>,
  code: string,
  delivery_date_from: string,
  delivery_date_to: string,
  delivery_date_real: string,
  production_date_from: string,
  production_date_to: string,
  note: string | null,
  document_link: string
  is_asap: boolean
}

const initialState = {
  viewOrder: {} as Order
}

export type InitialStateType = typeof initialState

export const ordersReducer = (state = initialState, action: Actions): InitialStateType => {
  switch (action.type) {
    case SET_ORDER:
      return {
        ...state,
        viewOrder: action.viewOrder
      }

    default:
      return state
  }
}

type Actions = setOrders

export interface setOrders {
  type: typeof SET_ORDER
  viewOrder: Order
}

export const setOrderForView = (order: Order): setOrders => ({ type: SET_ORDER, viewOrder: order })

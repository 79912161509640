import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { login } from '../../redux/reducers/authReducer'

import LoginReduxForm from './LoginReduxForm/LoginReduxForm'

import { FormData } from './../../types/typesComponent'
import { AppStateType } from '../../redux/rootReducer'

import style from './login.module.scss'

const Login: React.FC = () => {
  const isAuth = useSelector((state: AppStateType) => state.auth.isAuth)
  const dispatch = useDispatch()

  const onsubmit = (formData: FormData) => {
    dispatch(login(+formData.idUser))
  }
  
  if (isAuth) {
    return <Redirect to={'/'}/>
  }
  
  return (
    <div className={style.loginPage}>
      <div className={style.loginBlock}>
        <h2 className={style.title}>Авторизация</h2>

        <LoginReduxForm onSubmit={onsubmit}/>
      </div>
    </div>
  )
}

export default Login
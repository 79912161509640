import { SET_MODAL, SET_PAYMENTS } from '../../types/typesReducers'
import { Dispatch } from 'redux'
import { getPayments } from '../../api/api'
import { wsAPI } from '../../api/WSapi'

const initialState = {
  isAuth: false,
  isOpenModal: false,
  payments: ''
}

export type InitialStateType = typeof initialState

export const appReducer = (state = initialState, action: Actions): InitialStateType => {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...state,
        isOpenModal: action.isOpenModal
      }
    case SET_PAYMENTS:
      return {
        ...state,
        payments: action.payments
      }

    default:
      return state
  }
}

type Actions = ISetModal | ISetPayments

export interface ISetModal {
  type: typeof SET_MODAL
  isOpenModal: boolean
}
export interface ISetPayments {
  type: typeof SET_PAYMENTS
  payments: string
}

export interface IGetPayments {
  total: string,
}

export const setOpenModal = (isOpenModal: boolean): ISetModal => {
  return ({ type: SET_MODAL, isOpenModal: isOpenModal })
}
export const setPayments = (payments: string): ISetPayments => {
  return ({ type: SET_PAYMENTS, payments })
}

export const getPaymentsFromServer = (): (dispatch: Dispatch) => Promise<void | IGetPayments> => {
  return (dispatch: Dispatch) => {
    return getPayments.getPayments()
      .then((response) => {
        const sum = `${Number(response.total).toFixed()}`

        const addZeros = (total: string) => {
          if ((6 - total.length) > 0) {
            // return [].fill('0', 0, 6 - total.length).join('') + total
            return '0'.repeat(6 - total.length) + total
          }
          return total
        }
        const payment = ((6 - sum.length) > 0) ? addZeros(sum) : sum

        dispatch(setPayments(payment))
      })
  }
}

export const startWSChannel = (userId: number) => (): void => {
  wsAPI.start(userId)
}
export const stopWSChannel = () => (): void => {
  wsAPI.stop()
}
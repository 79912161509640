import React from 'react'
import { Redirect } from 'react-router-dom'
import { MainAppProps } from '../../App'

const withAuthRedirect = (Container: React.ElementType): React.FC<MainAppProps> => {
  const RedirectComponent: React.FC<MainAppProps> = (props) => {
    if ((!localStorage.getItem('token-employee') && !props.isAuth) || !props.isAuth) {
      return <Redirect to={'/sign-in'}/>
    }
    return <Container/>
  }

  return RedirectComponent
}

export default withAuthRedirect
import React from 'react'

import style from './modalConfirm.module.scss'

interface IProps {
  code: string,
  status: string,
  confirmCallback: (event: React.MouseEvent<HTMLButtonElement>) => void,
  cancelCallback: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

const ModalConfirm: React.FC<IProps> = ({ code, status= '', confirmCallback, cancelCallback }) => {
 const statusStyle = status === 'SUCCESS' ? 'success' : status === 'CANCEL' ? 'cancel' : ''
 const statusText = status === 'SUCCESS' ? 'завершить' : status === 'CANCEL' ? 'отменить' : ''

  return (
    <div className={style.modal}>
      <p className={style.modalText}>
        Вы действительно хотите <span className={style[statusStyle]}>{ statusText }</span> задачу { code }?</p>

      <div className={style.buttons}>
        <button className={style.button} onClick={confirmCallback}>Да</button>
        <button className={style.button} onClick={cancelCallback}>Нет</button>
      </div>
    </div>
  )
}

export default ModalConfirm
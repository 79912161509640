import React, { useEffect } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import Login from './components/Login/Login'
import Header from './components/Header/Header'
import ContentWrapper from './components/ContentWrapper/ContentWrapper'

import { AppStateType } from './redux/rootReducer'
import withAuthRedirect from './utils/hoc/withAuthRedirect'

import './App.scss'
import { Loader } from './components/Loader/Loader'
import { getAuthUserData } from './redux/reducers/authReducer'
import { startWSChannel, stopWSChannel } from './redux/reducers/appReducer'

export interface MainAppProps {
  isAuth: boolean
}

const MainApp: React.FC<MainAppProps> = () => {
  return (
    <>
      <Route path={'/'}>
        <Header />
        <ContentWrapper />
      </Route>
    </>
  )
}

const AppContainer = compose(withAuthRedirect)(MainApp)

const App: React.FC = () => {
  const isAuth = useSelector((state: AppStateType) => state?.auth?.isAuth)
  const userId = useSelector((state: AppStateType) => state?.auth?.id)
  const isLoadingApp = useSelector((state: AppStateType) => state.auth.isLoadingApp)
  const payments = useSelector((state: AppStateType) => state.app.payments)
  const dispatch = useDispatch()

  useEffect((): () => void => {
    if (!isAuth) {
      dispatch(getAuthUserData())
    }
    if (isAuth && userId) {
      dispatch(startWSChannel(userId))
    }

    return () => dispatch(stopWSChannel())
  }, [isAuth, userId])

  if (isLoadingApp && !userId && !payments) return <Loader width={500} height={500}/>

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/sign-in" component={Login} />

          <AppContainer isAuth={isAuth} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
import React, { useState } from 'react'
import { completeTaskToServer, Tasks } from '../../../redux/reducers/tasksReducer'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../../redux/rootReducer'
import { setOrderForView } from '../../../redux/reducers/ordersReducer'
import { setOpenModal } from '../../../redux/reducers/appReducer'
import style from '../allTasks.module.scss'
import ModalConfirm from '../../ModalWrapper/ModalConfirm/ModalConfirm'
import Row from './Row/Row'

const RowWrapper: React.FC<Tasks> = (props) => {
  const isLoadingTasks = useSelector((state: AppStateType) => state.tasks.isLoadingTasks)
  const [isModalConfirm, setIsModalConfirm] = useState(false)
  const [isDisableButton, setIsDisableButton] = useState(false)
  const dispatch = useDispatch()

  // Завершение задачи
  const completeTask = () => {
    if (!isDisableButton && !isLoadingTasks) {
      setIsDisableButton(true)
      dispatch(completeTaskToServer(props.id, { status: 'finish' }, () => {
        setIsDisableButton(false)
        setIsModalConfirm(false)
      }))
    }
  }

  // показать информацию о договоре
  const showOrder = () => {
    dispatch(setOrderForView(props.order))
    dispatch(setOpenModal(true))
  }

  // Открыть модалку для подтверждения завершения задачи
  const openConfirmModal = () => {
    setIsModalConfirm(true)
  }
  return (
    <>
      <Row {...props}
           showOrder={showOrder}
           isDisableButton={isDisableButton}
           openConfirmModal={openConfirmModal}/>

      {
        isModalConfirm &&
        <div className={style.modalWrapper}>
          <ModalConfirm code={props.order.code}
                        confirmCallback={completeTask}
                        cancelCallback={() => setIsModalConfirm(false)}
                        status={'SUCCESS'}/>
        </div>
      }
    </>
  )
}

export default RowWrapper